<template>
  <div class="px-4">
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-terminal card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0">
                  <b>FIN Technographics</b> - <i>Vendor Coverage</i>
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <v-wait for="fetchingVendors">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="10"></content-placeholders-text>
          </content-placeholders>
        </template>
        <b-row class="page-sub-header">
          <b-col cols="6">
            <h5>FinTech Vendors</h5>
          </b-col>

          <b-col cols="6" class="text-right">
            <h6>Click on a Vendor for a list of Vendor Solutions</h6>
          </b-col>
        </b-row>

        <hr class="mt-0" />

        <b-row class="text-center">
          <b-col cols="12" class="mb-4">
            <b-button
              variant="info"
              v-for="letter in alphabets"
              :key="letter"
              :disabled="disableLink(letter)"
              class="left-border-btn cursor-pointer"
              @click="scrollVendorToView(letter)"
              ><b>{{ letter }}</b></b-button
            >
          </b-col>
        </b-row>

        <b-row v-if="vendors.length === 0">
          <b-col cols="12">
            <b-card class="text-center empty-result">
              No Records Found
            </b-card>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col cols="9" class="vendors-list">
            <b-card-group columns>
              <b-card class="mb-0" body-class="" title="#">
                <router-link
                  tag="div"
                  v-for="vendor in specialCharVendors"
                  :key="vendor.id"
                  class="cursor-pointer"
                  :to="{ path: '/technographics/vendor_report/' + vendor.id }"
                >
                  {{ vendor.name }}
                </router-link>
              </b-card>

              <b-card
                v-for="vendorGroup in groupedVendors"
                :key="vendorGroup.group"
                class="mb-0"
                :ref="'group_' + vendorGroup.group"
                :title="vendorGroup.group"
              >
                <router-link
                  tag="div"
                  v-for="vendor in vendorGroup.children"
                  :key="vendor.id"
                  class="cursor-pointer"
                  :to="{ path: '/technographics/vendor_report/' + vendor.id }"
                >
                  {{ vendor.name }}
                </router-link>
              </b-card>
            </b-card-group>
          </b-col>
          <b-col cols="3">
            <b-card class="bg-success base-color mt-3">
              <div slot="header">
                <div class="text-center">
                  <h5>Vendor Request</h5>
                </div>
              </div>
              <div>
                <div>
                  Don't see a Vendor you are looking for? Click here to add a
                  Vendor.
                </div>
                <div class="text-center mt-3">
                  <router-link
                    tag="button"
                    class="btn btn-primary fw-btn"
                    :to="{ name: 'request-vendor' }"
                  >
                    Request Vendor
                  </router-link>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </v-wait>
    </b-card>
  </div>
</template>

<script>
import router from '@/router'

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$wait.start('fetchingVendors')
      vm.$http.get('/api/bank_service_vendors', { handleErrors: true }).then(
        response => {
          vm.vendors = response.data.vendors
          document.title = 'FIN Technographics'
          vm.$wait.end('fetchingVendors')
        },
        () => {
          vm.$wait.end('fetchingVendors')
        }
      )
    })
  },
  data() {
    return {
      vendors: [],
      alphabets: [...Array(26).keys()].map(i => String.fromCharCode(i + 65))
    }
  },
  computed: {
    specialCharVendors() {
      return this.vendors.filter(vendor => !/^[a-z]+(.)*/i.test(vendor.name))
    },
    groupedVendors() {
      let generalCharVendors = this.vendors.filter(vendor =>
        /^[a-z]+(.)*/i.test(vendor.name)
      )
      return generalCharVendors.reduce((result, vendor) => {
        let group = vendor.name[0].toUpperCase()
        if (!result[group]) {
          result[group] = { group, children: [vendor] }
        } else {
          result[group].children.push(vendor)
        }
        return result
      }, {})
    }
  },
  methods: {
    redirectToService(id) {
      router.push('/technographics/service_report/' + id)
    },
    disableLink(letter) {
      return this.groupedVendors[letter] === undefined
    },
    scrollVendorToView(letter) {
      let element = this.$refs['group_' + letter][0]
      window.scrollTo({
        top: element.offsetTop + 100,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.left-border-btn {
  background-color: white;
  border: none;
  border-right: solid 1px #a4b7c1;
  line-height: 0.5rem;

  &:hover {
    background-color: #a4b7c1;
  }

  &:last-child {
    border-right: none;
  }

  &:focus {
    background-color: #20a8d8;
    box-shadow: none;
  }
}

.base-color {
  color: initial;
}
</style>
